var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-1"
  }, [_c('div', {
    staticClass: "mt-2 pl-2 ba-border__selected"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center rounded-sm border ml-0 py-2 pr-3 pl-1"
  }, [_c('div', {
    staticClass: "text-sm-3 text-black-90"
  }, [_vm._v(_vm._s(_vm.label))]), _c('div', {
    staticClass: "text-sm-2 text-blue-800 d-flex align-items-center px-2 font-weight-bold"
  }, [_c('div', {
    staticClass: "pointer"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-view",
      "width": "4px",
      "height": "14px"
    }
  })], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }