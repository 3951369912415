var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "h-100"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "nowrap",
    attrs: {
      "align-v": "center",
      "align-h": "between"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "align-items-center input__relative"
  }, [_c('b-icon', {
    staticClass: "icon--search",
    attrs: {
      "icon": "search"
    }
  }), _c('b-form-input', {
    staticClass: "border-gray-10 rounded-custom border bg-gray-light text-sm-2 input__feild",
    attrs: {
      "placeholder": "Search"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _vm._t("search-section")], 2), _c('b-col', [_vm._t("header-action-right")], 2)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._t("tooltip-section")], 2), _c('b-table', {
    staticClass: "h-100 table-tab border rounded-custom border-gray-light",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.items,
      "busy": _vm.isLoading,
      "filter": _vm.search,
      "hover": "",
      "responsive": "",
      "sticky-header": "",
      "borderless": "",
      "thead-class": "table__head--class",
      "thead-tr-class": "table-item__head",
      "tbody-class": "table-item",
      "tbody-tr-class": "table-item",
      "show-empty": ""
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('ba-loader')], 1)];
      },
      proxy: true
    }, {
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "text-center text-primary pt-2"
        }, [_vm._v(" " + _vm._s(scope.emptyText) + " ")])];
      }
    }, _vm._l(_vm.$scopedSlots, function (_, name) {
      return {
        key: name,
        fn: function (slotData) {
          return [_vm._t(name, null, null, slotData)];
        }
      };
    })], null, true)
  }, [_vm._l(_vm.$slots, function (_, name) {
    return _vm._t(name, null, {
      "slot": name
    });
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }