var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-profile d-flex align-items-center",
    class: {
      'user-profile--full': _vm.showText
    }
  }, [_c('div', {
    staticClass: "position-relative"
  }, [_vm.profileImageId ? _c('b-avatar', _vm._b({
    staticClass: "pointer",
    attrs: {
      "size": "2.5em"
    }
  }, 'b-avatar', _vm.imageOrAvatar, false)) : [_c('span', {
    staticClass: "default-profile-image text-white d-flex align-items-center justify-content-center pointer"
  }, [_vm._v(" " + _vm._s(_vm.getUserData.firstName[0].toUpperCase()) + _vm._s(_vm.getUserData.lastName[0].toUpperCase()) + " ")])], _c('div', {
    staticClass: "online position-absolute"
  })], 2), _vm.showText ? _c('div', {
    staticClass: "user-profile__text d-flex flex-column"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.getUserData.firstName) + " " + _vm._s(_vm.getUserData.lastName))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.getUserData.frcId))])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }