var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ba-user d-flex align-items-center"
  }, [_c('div', {
    staticClass: "ba-user__initials d-flex align-items-center justify-content-center mr-1",
    class: [`ba-user__initials--${_vm.user.firstName[0].toLowerCase()}`]
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.user.firstName[0].toUpperCase()) + _vm._s(_vm.user.lastName[0].toUpperCase()))])]), _vm.showName ? _c('p', {
    staticClass: "ba-user__name mb-0 text-black-100"
  }, [_vm._v(_vm._s(`${_vm.user.firstName} ${_vm.user.lastName}`))]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }