var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "id": _vm.modalId,
      "title": _vm.title,
      "description": _vm.description,
      "custom-content-class": "overflow-scroll selectable-modal__content"
    }
  }, [_c('b-row', _vm._l(_vm.items, function (item, ix) {
    return _c('b-col', {
      key: ix,
      attrs: {
        "cols": "12"
      }
    }, [_c('ba-selectable-modal-item', {
      attrs: {
        "item": item,
        "index": ix
      },
      on: {
        "click": function ($event) {
          return _vm.clickItem(item.route);
        }
      }
    })], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }