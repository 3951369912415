
import Vue from "vue";
import Papa from "papaparse";

export default Vue.extend({
    props: {
        fields: Array,
        isLoading: {
            type: Boolean,
            default: false,
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        rows: {
            type: Number,
            default: 1,
        },
        perPage: {
            type: Number,
            default: 1,
        },
        items: { type: Array, required: true },
    },
    data() {
        return {
            search: "",
        };
    },
    methods: {
        async downloadList() {
            const csv = Papa.unparse(this.items);

            const csvData = new Blob([csv], {
                type: "text/csv;charset=utf-8;",
            });
            let csvURL = null;
            if (navigator.msSaveBlob) {
                csvURL = navigator.msSaveBlob(csvData, "download.csv");
            } else {
                csvURL = window.URL.createObjectURL(csvData);
            }

            const tempLink = document.createElement("a");
            tempLink.href = csvURL as string;
            tempLink.setAttribute("download", "download.csv");
            tempLink.click();
        },
    },
});
