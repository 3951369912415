
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
    props: {
        showText: { type: Boolean, required: false, default: false },
    },
    computed: {
        ...mapGetters({
            getUserData: "auth/getUserData",
            files: "file/getFiles",
        }),
        ...mapGetters({ profileData: "auth/getUserData" }),
        profileImageId(): string {
            const { profileImgId } = this.getUserData;
            return profileImgId;
        },
        profileImageUrl(): string {
            const imageObj = this.files[this.profileImageId];
            return (imageObj && imageObj.presignedUrl) || null;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        imageOrAvatar(): any {
            return this.profileImageUrl ? { src: this.profileImageUrl } : { icon: "people-fill" };
        },
    },
    async mounted() {
        if (this.profileImageId) {
            await this.getFileUrl(this.profileImageId);
        }
    },
    methods: {
        ...mapActions({
            fetchFile: "file/fetchFile",
        }),
        async getFileUrl(filename: string): Promise<void> {
            try {
                await this.fetchFile(filename);
            } catch (e) {
                this.$bvToast.toast("", { title: "Unable to fetch file!", variant: "danger", solid: true });
            }
        },
    },
});
