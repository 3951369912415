var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "label--text text-gray-900 py-2"
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.required ? _c('span', {
    staticClass: "text-danger h6"
  }, [_vm._v("*")]) : _vm._e()]), _c('b-input-group', {
    staticClass: "d-flex w-100",
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, name) {
      return {
        key: name,
        fn: function (slotData) {
          return [_vm._t(name, null, null, slotData)];
        }
      };
    })], null, true)
  }, [_c('b-form-input', _vm._g(_vm._b({
    class: `bg-${_vm.classText} border input__field`,
    attrs: {
      "placeholder": _vm.placeholder,
      "type": _vm.type
    },
    on: {
      "keypress": function ($event) {
        _vm.type === 'number' ? _vm.isNumber($event) : true;
      }
    }
  }, 'b-form-input', _vm.$attrs, false), _vm.$listeners)), _vm._l(_vm.$slots, function (_, name) {
    return _vm._t(name, null, {
      "slot": name
    });
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }