var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "searchable-form-input"
  }, [_c('div', {
    staticClass: "search position-relative"
  }, [_vm.label ? _c('label', {
    staticClass: "text-sm-3"
  }, [_vm._v(_vm._s(_vm.label)), _vm.required ? _c('span', {
    staticClass: "text-danger pl-1 h6 pt-1"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c('b-input-group', {
    staticClass: "align-items-center border px-2 text-sm-2"
  }, [_c('b-input-group-prepend', [_c('span', {
    staticClass: "bg-neutral border-0 border-none"
  }, [_c('b-icon', {
    attrs: {
      "icon": "search"
    }
  })], 1)]), _c('b-form-input', {
    staticClass: "search-input py-4 pr-4 bg-neutral",
    attrs: {
      "placeholder": "Search",
      "autocomplete": "off"
    },
    on: {
      "focus": _vm.onFocus,
      "blur": _vm.onFocusOut,
      "keyup": _vm.search
    },
    model: {
      value: _vm.input,
      callback: function ($$v) {
        _vm.input = $$v;
      },
      expression: "input"
    }
  })], 1), _c('ul', {
    ref: "ul",
    staticClass: "position-absolute"
  }, _vm._l(_vm.values, function (value, index) {
    return _c('li', {
      key: index,
      ref: "li",
      refInFor: true
    }, [_c('a', {
      class: {
        found: _vm.isFound(value)
      },
      on: {
        "click": function ($event) {
          _vm.type === 'single' ? _vm.addSingleValue(value) : _vm.addMultipleValue(value);
        }
      }
    }, [_c('span', {
      staticClass: "span-main"
    }, [_c('ba-base-icon', {
      staticClass: "li-image",
      attrs: {
        "name": "ba-executive"
      }
    }), _c('span', {
      staticClass: "span-text"
    }, [_vm._v(_vm._s(value.fullName))])], 1), _c('span', {
      class: {
        spanSide: true,
        blue: _vm.checkType(value.roles) === 'blue',
        yellow: _vm.checkType(value.roles) === 'yellow'
      }
    }, [_vm._v(_vm._s(value.roles))])])]);
  }), 0)], 1), _vm._t("error"), _vm.valuesArray.length ? _c('label', {
    staticClass: "mb-1 text-sm-1"
  }, [_vm._v("SELECTED")]) : _vm._e(), _vm._l(_vm.valuesArray, function (value, index) {
    return _c('div', {
      key: index,
      staticClass: "selected_executive_main position-relative border border-left-0 border-right-0 pt-2 pb-4 pl-2 border-top-gray-500 border-bottom-gray-500"
    }, [_c('span', {
      staticClass: "line position-absolute bg-warning"
    }), _c('div', {
      staticClass: "selected_executive d-flex justify-content-between bg-neutral align-items-center rounded-sm ml-2 p-2"
    }, [_c('div', {
      staticClass: "avatar d-flex align-items-center"
    }, [_c('div', [_c('ba-base-icon', {
      attrs: {
        "name": "ba-executive"
      }
    })], 1), _c('div', {
      staticClass: "name_mail ml-2"
    }, [_c('div', {
      staticClass: "text-sm-3 black-90"
    }, [_vm._v(" " + _vm._s(value.fullName) + " ")]), _c('div', {
      staticClass: "text-sm-1 text-black-80"
    }, [_vm._v(" " + _vm._s(value.email) + " ")])])]), _c('div', {
      staticClass: "text-sm-2 text-blue-800 d-flex align-content-center px-2 bg-blue-100 designation"
    }, [_c('p', [_vm._v(_vm._s(value.roles))])]), _c('div', {
      staticClass: "text-danger",
      on: {
        "click": function ($event) {
          return _vm.deleteValue(value);
        }
      }
    }, [_vm._v("x")])])]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }