var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.onFocusOut,
      expression: "onFocusOut"
    }],
    staticClass: "searchable-form-input mb-5"
  }, [_c('div', {
    staticClass: "search position-relative"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', [_vm.label ? _c('label', {
    staticClass: "label--text"
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.required ? _c('span', {
    staticClass: "text-danger pl-1 h6 pt-1"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e()]), !_vm.noCreate ? _c('div', [_c('ba-button', {
    staticClass: "text-blue-90 text-decoration-none create__button",
    attrs: {
      "icon": "ba-edit",
      "text": _vm.createLabel,
      "disabled": _vm.loadingValues,
      "loading": _vm.loadingValues,
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('create');
      }
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('ba-base-icon', {
          attrs: {
            "name": "plus-icon-blue"
          }
        })];
      },
      proxy: true
    }], null, false, 2061422166)
  })], 1) : _vm._e()]), _c('b-input-group', {
    staticClass: "d-flex w-100 input__wrapper",
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center pl-4 pt-2 bg-neutral"
        }, [_c('ba-base-icon', {
          attrs: {
            "name": "ba-search-icon-new"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    staticClass: "search-input py-4 pr-4 pl-2 bg-neutral",
    attrs: {
      "placeholder": _vm.placeholder,
      "autocomplete": "off"
    },
    on: {
      "focus": _vm.onFocus
    },
    model: {
      value: _vm.input,
      callback: function ($$v) {
        _vm.input = $$v;
      },
      expression: "input"
    }
  })], 1), _c('div', {
    staticClass: "position-absolute w-100 bg-white",
    staticStyle: {
      "z-index": "999"
    }
  }, [_c('ul', {
    ref: "ul"
  }, [_c('li', {
    staticClass: "d-flex justify-content-between bg-white align-items-center mx-4 my-3"
  }, [_c('span', {
    staticClass: "list--name"
  }, [_vm._v(_vm._s(_vm.listTitle))])]), _c('li', {
    staticClass: "d-flex justify-content-end align-items-center px-4 py-3 header-nav",
    on: {
      "click": _vm.onFocusOut
    }
  }, [_c('ba-button', {
    staticClass: "bg-gray-dark text-white",
    attrs: {
      "text": "Finish Selection"
    },
    on: {
      "click": _vm.onFocusOut
    }
  })], 1), _vm._l(_vm.filteredValues, function (value, index) {
    return _c('li', {
      key: index,
      ref: "li",
      refInFor: true,
      staticClass: "list-items",
      on: {
        "click": function ($event) {
          _vm.type === 'single' ? _vm.addSingleValue(value) : _vm.addMultipleValue(value);
        }
      }
    }, [_c('a', {
      class: {
        found: _vm.isFound(value)
      }
    }, [_c('b-button', {
      attrs: {
        "variant": "transparent"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          _vm.type === 'single' ? _vm.addSingleValue(value) : _vm.addMultipleValue(value);
        }
      }
    }, [_c('b-icon', {
      staticClass: "my-2 mr-2 bg-white",
      attrs: {
        "id": `selectable-check-${value.id}`,
        "variant": "light",
        "font-scale": "1.3",
        "icon": _vm.isFound(value) ? 'check-square' : 'square'
      }
    })], 1), _c('span', {
      staticClass: "span-main"
    }, [_c('span', {
      staticClass: "span-text"
    }, [_vm._v(" " + _vm._s(value.fullName) + " ")])]), _c('b-badge', {
      staticClass: "px-3 py-2 rounded admin-badge",
      attrs: {
        "pill": ""
      }
    }, [_vm._v(_vm._s(value.roles || value.position))])], 1)]);
  })], 2)])], 1), _vm._t("error"), _c('div', {
    staticClass: "table-responsive set-corner-radius"
  }, [_vm.valuesArray.length ? _c('table', {
    staticClass: "table border border-left-gray-500 border-right-gray-500 border-top-0 border-bottom-gray-500"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_c('p', {
    staticClass: "mb-0 label-title-text"
  }, [_vm._v(_vm._s(_vm.selectedLabel))])]), _vm.selectedLabel === 'Selected Committee(s)' ? _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_c('p', {
    staticClass: "mb-0 label-title-text"
  }, [_vm._v("Members")])]) : _vm._e(), _vm.selectedLabel === 'Selected Director(s)' ? _c('th', [_c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "gap": "15px"
    }
  }, [_c('p', {
    staticClass: "mb-0 label-title-text"
  }, [_vm._v("Total: " + _vm._s(_vm.valuesArray.length))]), _c('p', {
    staticClass: "mb-0 label-title-text"
  }, [_vm._v("Male: " + _vm._s(_vm.maleDirector))]), _c('p', {
    staticClass: "mb-0 label-title-text"
  }, [_vm._v("Female: " + _vm._s(_vm.femaleDirector))])])]) : _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_c('p', {
    staticClass: "mb-0 label-title-text"
  }, [_vm._v(_vm._s(_vm.selectedLabel === "Selected Committee(s)" ? "Role" : "Position"))])])])]), _c('tbody', _vm._l(_vm.valuesArray, function (value, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('div', {
      staticClass: "name--title"
    }, [_vm._v(" " + _vm._s(value.fullName) + " ")]), _vm.label === 'Select Reviewer' ? _c('div', {
      staticClass: "text-sm-1 text-black-80"
    }, [_vm._v(" " + _vm._s(value.email) + " ")]) : _vm._e()]), _vm.selectedLabel !== 'Selected Committee(s)' ? _c('td', [_c('b-badge', {
      staticClass: "px-3 py-2 rounded admin-badge",
      attrs: {
        "pill": ""
      }
    }, [_vm._v(_vm._s(value.roles || value.position))])], 1) : _vm._e(), _vm.selectedLabel === 'Selected Committee(s)' ? _c('td', [_c('div', {
      staticClass: "designation"
    }, _vm._l(value.users, function (user) {
      return _c('div', {
        key: user.id
      }, [_c('p', {
        staticClass: "mb-2"
      }, [_vm._v(_vm._s(user.fullName))])]);
    }), 0)]) : _vm._e(), _vm.selectedLabel === 'Selected Committee(s)' ? _c('td', _vm._l(value.users, function (user) {
      return _c('div', {
        key: user.id
      }, [_c('b-badge', {
        staticClass: "px-3 py-2 mb-2 rounded admin-badge",
        attrs: {
          "pill": ""
        }
      }, [_vm._v(_vm._s(user.position))])], 1);
    }), 0) : _vm._e(), _c('td', [_c('div', {
      staticClass: "d-flex justify-content-end align-items-center"
    }, [_vm.isValueEditable ? _c('div', {
      staticClass: "text-primary mr-4 mt-1"
    }, [_c('div', {
      staticClass: "pointer d-flex",
      on: {
        "click": function ($event) {
          return _vm.$emit('editValue', value);
        }
      }
    }, [_c('ba-base-icon', {
      attrs: {
        "name": "ba-edit-icon-blue"
      }
    }), _c('P', {
      staticClass: "mb-0 edit--text"
    }, [_vm._v("Edit")])], 1)]) : _vm._e(), _c('div', {
      staticClass: "pointer",
      on: {
        "click": function ($event) {
          return _vm.deleteValue(value);
        }
      }
    }, [_c('ba-base-icon', {
      attrs: {
        "name": "ba-cancel-round"
      }
    })], 1)])])]);
  }), 0)]) : _c('table', {
    staticClass: "table border border-left-gray-500 border-right-gray-500 border-top-0 border-bottom-gray-500"
  }, [_c('p', {
    staticClass: "px-4 pt-3 no-value"
  }, [_vm._v("None selected yet")])])])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }