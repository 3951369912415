var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 pl-4"
  }, [_c('nav', {
    staticClass: "bm-item-list"
  }, [_vm._t("default", function () {
    return [_vm._l(_vm.items, function (item, ix) {
      return _c('div', {
        key: ix,
        staticClass: "d-flex pointer align-items-center py-4",
        class: {
          'opacity-20 cursor-not-allowed': item.status === _vm.IItemStatus.INACTIVE
        },
        on: {
          "click": function ($event) {
            return _vm.handleItemClick(item);
          }
        }
      }, [_c('ba-base-icon', {
        staticClass: "mr-2",
        attrs: {
          "height": "20px",
          "name": item.logo
        }
      }), _c('div', {
        staticClass: "text-Neutral-80 text-md mr-2"
      }, [_vm._v(" " + _vm._s(item.name) + " ")]), item.status === _vm.IItemStatus.INACTIVE ? _c('ba-base-icon', {
        staticClass: "ml-auto",
        attrs: {
          "name": "ba-coming-soon-icon",
          "height": "18px"
        }
      }) : _vm._e()], 1);
    }), _c('hr'), _c('div', {
      staticClass: "d-flex pointer",
      on: {
        "click": function ($event) {
          _vm.$router.push({
            name: _vm.HOME.INDEX.NAME
          });
          _vm.$emit('item-clicked');
        }
      }
    }, [_c('ba-base-icon', {
      staticClass: "mr-2",
      attrs: {
        "name": "ba-home",
        "height": "18px"
      }
    }), _c('p', {
      staticClass: "text-Neutral-80 text-md"
    }, [_vm._v("Home")])], 1), _c('div', {
      staticClass: "d-flex pointer",
      on: {
        "click": function ($event) {
          _vm.$router.push({
            name: _vm.SETTINGS.INDEX.NAME
          });
          _vm.$emit('item-clicked');
        }
      }
    }, [_c('ba-base-icon', {
      staticClass: "mr-2",
      attrs: {
        "name": "ba-settings",
        "height": "24px"
      }
    }), _c('p', {
      staticClass: "text-Neutral-80 text-md"
    }, [_vm._v("Settings")])], 1), _c('hr'), _c('div', {
      staticClass: "d-flex pointer bg-yellow-10 pt-3",
      on: {
        "click": function ($event) {
          _vm.$router.push({
            name: _vm.HELP.INDEX.NAME
          });
          _vm.$emit('item-clicked');
        }
      }
    }, [_c('ba-base-icon', {
      staticClass: "mr-2",
      attrs: {
        "name": "ba-help",
        "height": "24px"
      }
    }), _c('p', {
      staticClass: "text-Neutral-80 text-md"
    }, [_vm._v("FAQ")])], 1), _c('div', {
      staticClass: "d-flex pointer bg-yellow-10 pt-3",
      on: {
        "click": function ($event) {
          return _vm.$emit('item-clicked');
        }
      }
    }, [_c('ba-base-icon', {
      staticClass: "mr-2",
      attrs: {
        "name": "ba-help",
        "height": "24px"
      }
    }), _c('p', {
      staticClass: "text-Neutral-80 text-md"
    }, [_vm._v("Help")])], 1)];
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }