
import Vue from "vue";

interface Ivalue {
    id: string;
    imageId: string;
    fullName: string;
    email: string;
    roles: string;
}

enum PositionType {
    executive = "executive",
    director = "director",
    employee = "employee",
}

export default Vue.extend({
    inheritAttrs: true,
    props: {
        "label": {
            default: "Label",
        },
        "selected-label": {
            default: "SELECTED",
        },
        "create-label": {
            default: "SELECTED",
        },
        "keyValue": {
            type: String,
        },
        "type": {
            default: "multiple",
        },
        "values": {
            default: () => [],
        },
        "required": {
            type: Boolean,
            default: false,
        },
        "loading-values": {
            type: Boolean,
            default: false,
        },
        "no-create": {
            type: Boolean,
            default: false,
        },
        "is-value-editable": {
            type: Boolean,
            default: false,
        },
        "defaultValue": {
            default: () => [],
        },
        "listTitle": {
            type: String,
            default: "List Name",
        },
        "placeholder": {
            type: String,
            default: "Search to Add",
        },
    },
    data(): { input: string; valuesArray: Array<Ivalue> } {
        return {
            input: "",
            valuesArray: [],
        };
    },
    computed: {
        maleDirector(): any {
            return this.valuesArray.filter((data: any) => data.gender === 1).length;
        },
        femaleDirector(): any {
            return this.valuesArray.filter((data: any) => data.gender === 2).length;
        },
        filteredValues(): any {
            if (this.input) {
                return this.values.filter((value: any) => value.fullName.toLowerCase().includes(this.input.toLowerCase()));
            }
            return this.values;
        },
    },
    methods: {
        isFound(value: Ivalue) {
            const index = this.valuesArray.findIndex((val: Ivalue) => val.id === value.id);
            if (index >= 0) {
                return true;
            }
            return false;
        },
        handleCheckboxSelect(value: Ivalue) {
            if (this.type === "single") {
                console.log(this.isFound(value), value.id);
                if (!this.isFound(value)) {
                    this.addSingleValue(value);
                } else {
                    console.log("delll", this.isFound(value), value.id, this.valuesArray);

                    this.$nextTick(() => {
                        this.clearSelection();
                        this.resetLiInputs();
                    });
                }
            } else {
                this.addMultipleValue(value);
            }
        },
        onFocus() {
            const ulTag = this.$refs.ul as any;
            if (ulTag) {
                ulTag.style.display = "block";
            }
        },
        closeSelect() {
            const ulTag = this.$refs.ul as any;
            if (ulTag) {
                setTimeout(() => {
                    ulTag.style.display = "none";
                }, 150);
            }
        },
        clearSelection() {
            this.valuesArray = [];
            this.$emit("input", []);
        },
        onFocusOut() {
            const ulTag = this.$refs.ul as any;
            if (ulTag) {
                this.closeSelect();
                this.clearInput();
            }
        },
        clearInput() {
            this.input = "";
            setTimeout(() => {
                this.resetLiInputs();
            }, 150);
        },
        resetLiInputs() {
            const liTags = this.$refs.li as any;
            for (let i = 0; i < liTags.length; i += 1) {
                liTags[i].style.display = "";
            }
        },
        addSingleValue(value: Ivalue) {
            this.valuesArray.splice(0, 1, value);

            this.$emit("input", this.valuesArray);

            this.clearInput();
        },
        addMultipleValue(value: Ivalue) {
            const index = this.valuesArray.findIndex((val: Ivalue) => val.id === value.id);
            if (index >= 0) {
                this.valuesArray.splice(index, 1);
            } else {
                this.valuesArray.push(value);
            }

            this.$emit("input", this.valuesArray);

            this.clearInput();
        },
        deleteValue(value: Ivalue) {
            const index = this.valuesArray.findIndex((val: Ivalue) => val.id === value.id);
            console.log("deeeelete", index);
            this.valuesArray.splice(index, 1);
        },
        // search() {
        //     const liTags = this.$refs.li as any;
        //     for (let i = 0; i < liTags.length; i += 1) {
        //         const a = liTags[i].getElementsByTagName("a")[0];
        //         const txtValue = a.textContent || a.innerText;
        //         const filter = this.input.toUpperCase();
        //         if (txtValue.toUpperCase().includes(filter)) {
        //             liTags[i].style.display = "";
        //         } else {
        //             liTags[i].style.display = "none";
        //         }
        //     }
        // },
        checkType(value: string) {
            switch (value?.toLowerCase()) {
                case PositionType.executive:
                case PositionType.employee:
                    return "blue";
                case PositionType.director:
                    return "yellow";
                default:
                    return "blue";
            }
        },
    },
    watch: {
        defaultValue(v) {
            console.log("changinggg", v);
            this.valuesArray = v;
        },
        values(v) {
            // console.log(v);
        },
    },
    mounted() {
        this.valuesArray = this.defaultValue;
    },
});
