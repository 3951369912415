var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tabs position-relative"
  }, [_c('div', {
    staticClass: "inner position-relative d-flex flex-nowrap w-100"
  }, [_vm._l(_vm.tabs, function (tab, index) {
    return _c('button', {
      key: tab.key,
      ref: tab.key,
      refInFor: true,
      staticClass: "bg-transparent border-0 text-left",
      class: [{
        active: _vm.activeTab === tab.key
      }, {
        padded: index === 0 && _vm.haveInitialMargin
      }],
      on: {
        "click": function ($event) {
          return _vm.$emit('set-active-tab', tab.key);
        }
      }
    }, [_vm._v(" " + _vm._s(tab.value) + " ")]);
  }), _c('span', {
    staticClass: "indicator position-absolute",
    style: {
      width: `${_vm.activeLineWidth}px`,
      transform: `translateX(${_vm.activeLineOffset}px)`
    }
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }