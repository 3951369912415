
import Vue from "vue";

export default Vue.extend({
    inheritAttrs: true,
    props: {
        label: {
            type: String,
            required: false,
            default: "",
        },
        placeholder: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        classText: {
            type: String,
            required: false,
            default: "transparent",
        },
        usage: {
            type: String,
            required: false,
            default: "default",
        },
    },
});
