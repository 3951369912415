var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.label ? _c('div', {
    staticClass: "label--text text-gray-900 py-2"
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.required ? _c('span', {
    staticClass: "text-danger h6"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c('b-textarea', _vm._g(_vm._b({
    class: `bg-${_vm.classText} ba-textarea--${_vm.usage}`,
    attrs: {
      "placeholder": _vm.placeholder
    }
  }, 'b-textarea', _vm.$attrs, false), _vm.$listeners))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }