
import Vue from "vue";

interface Refs {
    [key: string]: HTMLElement[];
}

export default Vue.extend({
    props: {
        tabs: { type: Array, required: true },
        activeTab: { type: String, required: true },
        haveInitialMargin: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            activeLineWidth: 0,
            activeLineOffset: 0,
            newTab: "",
        };
    },
    watch: {
        activeTab(newActiveTab) {
            if (this.newTab === newActiveTab) return;
            this.moveActiveLine(newActiveTab);
            this.newTab = newActiveTab;
        },
    },
    mounted() {
        console.log("mounted >>>>>>>>", this.activeTab);
        setTimeout(() => {
            this.moveActiveLine(this.activeTab);
        }, 10);
    },
    methods: {
        setActiveTab(tab: string) {
            this.$emit("set-active-tab", tab);
            this.moveActiveLine(tab);
            this.newTab = tab;
        },
        moveActiveLine(newValue: string) {
            if (!this.activeTab) return;
            if (!this.$refs || !this.$refs[newValue] || !(this.$refs as Refs)[newValue][0]) return;
            const element = (this.$refs as Refs)[newValue][0];
            this.activeLineWidth = element.clientWidth;
            this.activeLineOffset = element.offsetLeft;
        },
    },
});
