
import Vue from "vue";

export default Vue.extend({
    props: {
        user: {
            type: Object,
            required: true,
        },
        showName: { type: Boolean, required: false, default: true },
    },
});
