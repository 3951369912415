var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column mb-4 ba-file-input ba-file-input__container"
  }, [_c('div', {
    staticClass: "pt-1 text-black"
  }, [_vm._v(_vm._s(_vm.label))]), _c('div', {
    staticClass: "pt-1 text-sm-2 mb-3"
  }, [_vm._v(_vm._s(_vm.description))]), _c('ba-dashed-box', {
    staticClass: "d-flex bg-gray-500 justify-content-between align-items-center pl-3 pr-6 py-3 rounded-sm ba-file-input__file",
    attrs: {
      "stroke": "rgba(209, 208, 204, 1)"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('ba-base-icon', {
    staticClass: "mr-3",
    attrs: {
      "name": "ba-pdf"
    }
  }), _c('div', [_c('div', {
    staticClass: "text-black"
  }, [_vm._v("name.pdf")]), _c('div', {
    staticClass: "text-sm font-weight-bold text-gray-900"
  }, [_vm._v(" 12MB ")])])], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "text-blue-900 pointer"
  }, [_vm._v("View")])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }