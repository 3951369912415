var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    class: `ba-selectable-modal-item
        d-flex
        flex-column
        w-100
        px-5
        py-4
        align-items-center
        justify-content-center
        rounded
        border border-gray-500
        mb-4
        pointer ${_vm.circlePosition} ${_vm.isHovered ? 'bg-black' : 'bg-white'}`,
    on: {
      "mouseenter": function ($event) {
        _vm.isHovered = true;
      },
      "mouseleave": function ($event) {
        _vm.isHovered = false;
      }
    }
  }, _vm.$listeners), [_c('div', {
    class: `pt-1 ${!_vm.isHovered ? 'text-black' : 'text-white'}`
  }, [_vm._v(" " + _vm._s(_vm.item.title) + " ")]), _c('div', {
    staticClass: "pb-1 pt-2 text-center"
  }, [_vm._v(" " + _vm._s(_vm.item.description) + " ")]), _vm.item.image ? _c('div', {
    class: `image ${_vm.imagePosition} ${_vm.item.imageClass}`
  }, [_c('ba-base-icon', {
    attrs: {
      "name": _vm.item.image
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }